/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(./Fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format("woff2");
  font-display: fallback;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(./Fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format("woff2");
  font-display: fallback;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(./Fonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format("woff2");
  font-display: fallback;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(./Fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format("woff2");
  font-display: fallback;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(./Fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format("woff2");
  font-display: fallback;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  src: local("Lato Hairline"), local("Lato-Hairline"),
    url(./Fonts/S6u8w4BMUTPHh30AUi-qJCY.woff2) format("woff2");
  font-display: fallback;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  src: local("Lato Hairline"), local("Lato-Hairline"),
    url(./Fonts/S6u8w4BMUTPHh30AXC-q.woff2) format("woff2");
  font-display: fallback;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  20% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  90% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  20% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  90% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes animate-top-bar {
  0% {
    background-color: #fff;
  }
  50% {
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
  }
  80% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    background-color: #fff;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #fff;
  }
}

@keyframes animate-top-bar {
  0% {
    background-color: #fff;
  }
  50% {
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
  }
  80% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    background-color: #fff;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #fff;
  }
}

@-webkit-keyframes animate-bottom-bar {
  0% {
    background-color: #fff;
  }
  50% {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
  }
  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    background-color: #fff;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #fff;
  }
}

@keyframes animate-bottom-bar {
  0% {
    background-color: #fff;
  }
  50% {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
  }
  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    background-color: #fff;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #fff;
  }
}

@-webkit-keyframes animate-middle-bar {
  0% {
    background-color: #fff;
  }
  80% {
    background-color: #fff;
  }
  100% {
    background-color: #fff;
  }
}

@keyframes animate-middle-bar {
  0% {
    background-color: #fff;
  }
  80% {
    background-color: #fff;
  }
  100% {
    background-color: #fff;
  }
}

@-webkit-keyframes animate-out-top-bar {
  0% {
    background-color: #fff;
  }
  50% {
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
  }
  80% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    background-color: #fff;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #fff;
  }
}

@keyframes animate-out-top-bar {
  0% {
    background-color: #fff;
  }
  50% {
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
  }
  80% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    background-color: #fff;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #fff;
  }
}

@-webkit-keyframes animate-out-bottom-bar {
  0% {
    background-color: #fff;
  }
  50% {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
  }
  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    background-color: #fff;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #fff;
  }
}

@keyframes animate-out-bottom-bar {
  0% {
    background-color: #fff;
  }
  50% {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
  }
  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    background-color: #fff;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #fff;
  }
}

@-webkit-keyframes animate-out-middle-bar {
  0% {
    background-color: #fff;
  }
  80% {
    background-color: #fff;
  }
  100% {
    background-color: #fff;
  }
}

@keyframes animate-out-middle-bar {
  0% {
    background-color: #fff;
  }
  80% {
    background-color: #fff;
  }
  100% {
    background-color: #fff;
  }
}

.floating {
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes floating {
  from {
    -webkit-transform: translate(0, 0px) rotate(0.1deg);
    transform: translate(0, 0px) rotate(0.1deg);
  }
  65% {
    -webkit-transform: translate(0, 20px) rotate(0.1deg);
    transform: translate(0, 20px) rotate(0.1deg);
  }
  to {
    -webkit-transform: translate(0, 0px) rotate(0.1deg);
    transform: translate(0, 0px) rotate(0.1deg);
  }
}

@keyframes floating {
  from {
    -webkit-transform: translate(0, 0px) rotate(0.1deg);
    transform: translate(0, 0px) rotate(0.1deg);
  }
  65% {
    -webkit-transform: translate(0, 20px) rotate(0.1deg);
    transform: translate(0, 20px) rotate(0.1deg);
  }
  to {
    -webkit-transform: translate(0, 0px) rotate(0.1deg);
    transform: translate(0, 0px) rotate(0.1deg);
  }
}

.purple {
  color: #8e44ad;
}

html {
  font-size: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 3 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 20px;
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  height: 100%;
  width: 100%;
  /* background: #512888; */
  background: #421084;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: #222222;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Arial, sans-serif;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

html,
body {
  height: 100%;
  width: 100%;
}

ul {
  padding: 0;
  list-style: none;
}

object {
  min-height: 20px;
  pointer-events: none;
}

.project-logo object {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  display: none; /* JS - display page when DOM has loaded */
}

hr {
  max-width: 50px;
}

hr.light {
  border-color: white;
}
a {
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  color: #fff;
}
a:hover,
a:focus {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

img {
  width: 100%;
  height: 100%;
}

.nav > li > a:focus,
.nav > li > a:hover {
  color: #fff;
  background-color: transparent;
}
#header h2 {
  font-family: "Lato", sans-serif;
  font-weight: 100;
}

@media (max-width: 768px) {
  .letters.signature span {
    font-size: 1.5rem;
  }
}

h1,
h2,
h3,
h5,
h6 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 1.5px;
}
h2 {
  font-size: 1.4rem;
  font-weight: 700;
}
h4 {
  color: #fff;
  font-size: 1rem;
}
p {
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media (min-width: 1367px) {
  p {
    line-height: 1.7;
  }
}

@media (min-width: 1920px) {
  p {
    line-height: 2;
  }
}

@media only screen and (min-width: 850px) {
  section {
    height: 100vh;
  }

  .project-btn {
    opacity: 0;
  }
}

section {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 75px 0;
  min-height: 100vh;
}

@media (max-width: 1024px) {
  section {
    min-height: auto;
  }
}

span {
  font-size: 1.1rem;
}

.no-scroll {
  overflow: hidden;
}

.center-vertical {
  position: absolute !important;
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.d-in-bl {
  display: inline-block;
}

.flex-col {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.align-items--center {
  align-items: center;
}

.flex-grow--1 {
  flex-grow: 1;
}

.flex-shrink--0 {
  flex-shrink: 0;
}

#progressbar-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10005;
  background-color: #421084;
  overflow: hidden;
}

#progressbar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0;
}

#contact {
  position: relative;
  width: 100%;
}

#contact .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1a005c;
  opacity: 0.8;
  z-index: 5;
  display: none;
  opacity: 0;
}

@media (min-width: 1000px) {
  #contact {
    padding: 0;
  }
}

@media (max-width: 768px) {
  #contact .scene,
  #header .scene {
    padding: 0;
  }
}

#message {
  font-family: "Montserrat", sans-serif;
  position: relative;
  z-index: 10;
}

#contact p {
  font-size: 18px;
}
#about {
  position: relative;
  width: 100%;
}

.app-pane {
  position: absolute;
  bottom: 20%;
  right: -100px;
  width: 100px;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.2);
  opacity: 0.6;
  z-index: -3;
}

.app-pane:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 100px solid transparent;
  border-top: 60px solid rgba(20, 20, 20, 0.2);
  position: absolute;
  bottom: 0;
  transform: translate(0%, 100%);
  opacity: 1;
}

.pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 1px 6px 0 rgba(32, 33, 36, 0.28);
}

#about .pane {
  background-color: rgba(20, 20, 20, 0.2);
  width: 0;
  opacity: 0.8;
  min-height: 350px;
}

#about .pane .text-list {
  display: none;
  opacity: 0;
}

#about p,
#contact p,
#about h2,
#contact h2 {
  color: #fff;
}

#about .text-list h2 {
  font-size: 1.4rem;
}

#about .layer .col-lg-10 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 768px) and (max-width: 1366px) {
  #about p {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 1024px) {
  .text-list {
    width: calc(80% - 1.875rem);
    float: left;
    margin-left: 20px;
    margin-left: 1.25rem;
    padding-right: 30px;
    padding-right: 1.875rem;
  }
}

#skills {
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 5;
}

#skills .overlay {
  position: absolute;
  top: 0;
  left: 8vw;
  right: 0;
  bottom: 10vh;
  background-color: rgba(20, 20, 20, 0.2);
  box-shadow: 0px 2px 6px 0 rgba(32, 33, 36, 0.28);
}

@media (max-width: 1024px) {
  #skills .overlay {
    left: 0;
  }

  #skills .skills-panel-wrapper {
    margin-top: 5rem;
  }
}

#skills .pane {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  min-height: 10px;
  background-color: #684b81;
  opacity: 0.6;
  box-shadow: 0px 4px 8px 0 rgba(32, 33, 36, 0.28);
  z-index: -3;
}

#skills .col-lg-10 {
  height: 100%;
}

#skills .skills-panel-wrapper {
  display: flex;
  justify-content: space-around;
  opacity: 0;
  transform: translateY(100px) scale(1.1);
}

@media (max-width: 800px) {
  #skills .skills-panel-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  #skills .skills-panel-wrapper .skills-panel {
    margin-bottom: 20px;
    width: 100% !important;
  }
}

@media (min-width: 1500px) {
  #skills .skills-panel-wrapper {
    padding: 50px;
  }
}

@media (min-width: 1920px) {
  #skills .skills-panel-wrapper {
    padding: 100px;
  }
}

#skills .skills-panel-wrapper .skills-panel {
  position: relative;
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: #512888;
  border-radius: 5px;
  overflow: hidden;
}

#skills .panel-content {
  padding: 10px 20px;
  text-align: center;
  padding-bottom: 30px;
}

#skills .panel-content .stn-btn {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

#skills .panel-content h4 {
  color: #fff;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
}

#skills .panel-content p {
  color: #fff;
  font-size: 0.9rem;
  text-align: left;
}

#skills .panel-img-wrapper {
  position: absolute;
  opacity: 0.2;
  top: 100px;
  right: -100px;
  -webkit-transform: translateZ(20px);
  transform: translateZ(20px);
}

#skills .panel-img-wrapper.sass {
  right: 50px;
  width: 400px;
}

#skills .panel-img-wrapper.react {
  right: 0;
  width: 400px;
}

#skills .panel-img-wrapper.node {
  width: 300px;
}

#what-i-do {
  position: relative;
  padding: 5rem 8rem;
  background-color: #4b0f8e;
  max-height: 50vh;
  min-height: auto;
  opacity: 0;
  transform: translate(0, 100px);
}

@media (max-width: 768px) {
  #what-i-do {
    padding: 2rem 4rem;
    min-height: 40vh;
    max-height: unset;
  }
}

#what-i-do .list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  z-index: 60;
  opacity: 0;
  transform: translate(0, 100px);
}

#what-i-do .list .item > span {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  opacity: 0.7;
  transition: 300ms opacity ease-out;
}

#what-i-do .list .item > span:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fff;
  display: inline-block;
  border-radius: 100%;
  transform: translateY(-50%);
  margin-right: 10px;
}

#what-i-do .list .item > span:hover {
  cursor: pointer;
  opacity: 1;
}

#what-i-do .mask-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40vw;
  overflow: hidden;
}

#what-i-do .item--mask {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #4b0f8e;
  z-index: 53;
}

#what-i-do .item-bg--1,
#what-i-do .item-bg--2,
#what-i-do .item-bg--3 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
}

@media (max-width: 1024px) {
  #what-i-do .item--mask {
    display: none;
  }

  #what-i-do .item-bg--1,
  #what-i-do .item-bg--2,
  #what-i-do .item-bg--3 {
    display: none;
  }
}

#what-i-do .item-bg--1 {
  background: url("../img/web_design_background.jpg");
  background-size: cover;
  background-position: center;
}

#what-i-do .item-bg--2 {
  background: url("../img/web_development_background.jpg");
  background-size: cover;
  background-position: center;
}

#what-i-do .item-bg--3 {
  background: url("../img/mobile_development_background.jpg");
  background-size: cover;
  background-position: center;
}

#what-i-do .info-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60vw;
  overflow: hidden;
}

#what-i-do .item-info p {
  color: #fff;
}

#what-i-do .item-info--1,
#what-i-do .item-info--2,
#what-i-do .item-info--3 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  transform: translate(-100%, 0);
  background-color: #581b8b;
  overflow: hidden;
  z-index: 424;
}

#what-i-do .close-item {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

#what-i-do .close-item span {
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
  transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
}

@media (max-width: 1024px) {
  #what-i-do .info-wrapper {
    width: 100vw;
  }

  #what-i-do .close-item span {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 768px) {
  .content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 50px;
    width: 100%;
    height: 100%;
    padding: 50px 0;
  }
  .content-inner {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
.text-faded {
  color: rgba(255, 255, 255, 0.7);
}

.overlay-navigation {
  width: 0;
  height: 100vh;
  position: fixed;
  background: #512888;
  z-index: 52;
  overflow: hidden;
  opacity: 0.3;
}

.overlay-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
#navbar-responsive {
  position: absolute;
  top: 50%;
  left: 12.5rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 5%;
}

@media (max-width: 1500px) {
  #navbar-responsive {
    left: 10rem;
  }
}

@media (max-width: 1024px) {
  #navbar-responsive {
    left: 8rem;
  }
}

@media (max-width: 768px) {
  #navbar-responsive {
    left: 5rem;
  }
}

#navbar-responsive li > a {
  color: #fff;
  font-size: 1.3rem;
  -webkit-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}

#navbar-responsive li > a:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition-timing-function: ease;
}

#navbar-responsive li a:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
  z-index: 2;
  will-change: transform, color;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

#navbar-responsive .nav {
  opacity: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.nav-buttons {
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 55;
}

.nav-box {
  position: relative;
  left: 0;
  height: 100%;
  width: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 53;
  box-shadow: 0px 2px 6px 0 rgba(32, 33, 36, 0.28);
}

.nav-line {
  position: absolute;
  top: 75%;
  left: 0;
  width: 0;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.8);
}

.nav-social {
  position: absolute;
  width: 20%;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: center;
  opacity: 0;
  /* JS */
}

@media (max-width: 850px) {
  .nav-social > svg {
    width: 60px;
    margin-left: -100px;
  }
}

#navlogo {
  position: absolute;
  left: 2rem;
  top: 1rem;
  z-index: 100;
  display: block;
  cursor: pointer;
  max-width: 156px;
}

@media (max-width: 768px) {
  #navlogo {
    display: none;
  }
}

.open-overlay {
  position: absolute;
  right: 2rem;
  top: 1rem;
  z-index: 100;
  width: 34px;
  display: block;
  cursor: pointer;
  margin: 10px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.open-overlay span {
  display: block;
  height: 1px;
  background-color: #fff;
  cursor: pointer;
  margin-top: 8px;
}

.animate-top-bar {
  -webkit-animation: animate-top-bar 0.6s linear 1 both;
  animation: animate-top-bar 0.6s linear 1 both;
}

.animate-bottom-bar {
  -webkit-animation: animate-bottom-bar 0.6s linear 1 both;
  animation: animate-bottom-bar 0.6s linear 1 both;
}

.animate-middle-bar {
  -webkit-animation: animate-middle-bar 0.6s linear 1 both;
  animation: animate-middle-bar 0.6s linear 1 both;
}

.animate-out-top-bar {
  -webkit-animation: animate-out-top-bar 0.6s linear 1 both;
  animation: animate-out-top-bar 0.6s linear 1 both;
}

.animate-out-bottom-bar {
  -webkit-animation: animate-out-bottom-bar 0.6s linear 1 both;
  animation: animate-out-bottom-bar 0.6s linear 1 both;
}

.animate-out-middle-bar {
  -webkit-animation: animate-out-middle-bar 0.6s linear 1 both;
  animation: animate-out-middle-bar 0.6s linear 1 both;
}

.navbrand {
  float: left;
  height: 50px;
  font-size: 18px;
  line-height: 20px;
}

#header {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: auto;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  text-align: center;
  color: white;
  padding: 0;
}

.homeHeading-wrapper {
  padding: 1rem 0;
  text-align: left;
  z-index: 9999;
}

.homeHeading-wrapper,
.signature-wrapper {
  opacity: 0;
  transform: translate(0, 50px);
}

@media (max-width: 768px) {
  #header .homeHeading-wrapper {
    padding: 0;
  }
}

#header #homeHeading h2 {
  color: #fff;
  margin: 0 5rem;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 3rem;
  letter-spacing: 1.5px;
  line-height: 1.3;
  z-index: 9999;
}

#header .signature {
  margin: 0 5rem;
  font-size: 3rem;
  text-align: left;
  text-transform: uppercase;
}

#header .signature span {
  color: #fff;
  font-size: 2.3rem;
  font-weight: 100;
  letter-spacing: 2.5px;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  text-shadow: 0px 1px 1px;
  -webkit-transform: translateZ(20px);
  transform: translateZ(20px);
  z-index: 9999;
}

@media (max-width: 1024px) {
  #header #homeHeading h2,
  #header .signature {
    margin: 0 2rem;
  }

  #header .header-content .pane {
    margin: 0 2rem 1rem;
  }
}

#header .header-content {
  position: relative;
  text-align: center;
  padding: 100px 15px 100px;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  #header .header-content {
    padding: 10px;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  #header .homeHeading-wrapper {
    padding: 0;
  }

  #header .signature {
    margin-top: 0;
  }
}

#header .header-content .header-content-inner h1 {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
}
#header .header-content .header-content-inner hr {
  margin: 30px auto;
}
#header .header-content .header-content-inner p {
  font-weight: 300;
  color: white;
  font-size: 14px;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  #header {
    min-height: 100%;
  }
  #header .header-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 50px;
  }
  #header .header-content .header-content-inner {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 480px) and (orientation: portrait) {
  #header {
    min-height: 100%;
  }
  #header .header-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 50px;
  }
  #header .header-content .header-content-inner {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  #header .header-content .header-content-inner h1 {
    font-size: 50px;
  }
  #header .header-content .header-content-inner p {
    font-size: 20px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.header-content .pane {
  width: 0;
  min-height: 5px;
  margin: 0 5rem 1rem;
  background-color: #684b81;
  opacity: 0.6;
  z-index: -3;
  box-shadow: none;
}

.header-content .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 10vw;
  bottom: 10vh;
  background-color: #42007e;
  box-shadow: 0px 2px 6px 0 rgba(32, 33, 36, 0.28);
}

.header-content .overlay:nth-child(2) {
  position: absolute;
  height: 50vh;
  top: auto;
  left: auto;
  font-size: 20rem;
  font-family: "Montserrat";
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.3;
  z-index: -5;
  bottom: -114px;
  right: -500px;
  background-color: #684b81;
  width: 0;
  box-shadow: 0px 1px 6px 0 rgba(32, 33, 36, 0.28);
}

@media (max-width: 1024px) {
  .header-content .overlay:nth-child(2) {
    bottom: -100px;
    right: -50px;
  }
}

.header-content .overlay .box {
  top: auto;
  left: auto;
  opacity: 1;
  width: 0;
}

.header-content .overlay .box:first-child {
  right: 20%;
  top: 50%;
  height: 100px;
  background-color: #4f1d79;
}

.header-content .overlay .box:nth-child(2) {
  right: 10%;
  top: 50%;
  margin-top: 50px;
  height: 200px;
  background-color: #581b8b;
}

@media (max-width: 1024px) {
  .header-content .overlay .box:first-child {
    bottom: 21%;
    right: 50%;
    margin-right: -150px;
    height: 75px;
  }

  .header-content .overlay .box:nth-child(2) {
    bottom: 5%;
    right: 50%;
    margin-right: -250px;
    height: 150px;
  }
}

@media (max-width: 768px) {
  #about .content-inner {
    margin-left: 1.25rem;
  }
}

.section-heading {
  font-size: 1.8rem;
  font-weight: 100;
  letter-spacing: 2.5px;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  margin-top: 0;
}

#portfolio {
  position: relative;
  width: 100%;
  min-height: 150vh;
  height: auto;
  padding: 0;
}

@media (max-width: 768px) and (orientation: portrait) {
  #portfolio .projects-wrapper .scene {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  #portfolio {
    padding-bottom: 0;
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

.box {
  position: absolute;
  top: 80%;
  left: 40%;
  width: 300px;
  height: 300px;
  background-color: #684b81;
  opacity: 0.5;
  box-shadow: 0px -4px 8px 0 rgba(32, 33, 36, 0.28);
}

#about .box {
  top: 50%;
  left: 65%;
}

@media (max-width: 1024px) {
  #about .pane .flex-col {
    padding: 0 4rem;
  }
}

#portfolio .pane {
  box-shadow: 0px 1px 6px 0 rgba(32, 33, 36, 0.28);
  width: 0;
  opacity: 1;
  background-color: #42007e;
  min-height: 100px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

#portfolio .pane > h2 {
  display: none;
  color: #fff;
  margin: 0 2rem;
  font-size: 1.4rem;
  text-align: right;
  opacity: 0;
}

@media (max-width: 768px) {
  #portfolio .pane > h2 {
    text-align: center;
  }
}

.overview .box {
  width: 80px;
  height: 80px;
}

.overview .box.inn.vert {
  top: 90%;
  left: 20%;
  opacity: 0.3;
}

.overview .box.inn:nth-child(2) {
  top: 14%;
  left: 85%;
  opacity: 0.3;
  width: 200px;
  height: 200px;
}

.overview .box.inn:nth-child(3) {
  top: 50%;
  left: auto;
  right: 30%;
  opacity: 0.2;
  width: 200px;
  height: 200px;
}

#portfolio .wrapper {
  margin-top: 10rem;
  padding: 2rem;
}

@media (max-width: 1024px) {
  #portfolio .wrapper {
    padding: 2rem;
  }
}

#portfolio .project {
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 6px 0 rgba(32, 33, 36, 0.28);
}

#portfolio .project {
  opacity: 0;
  transform: translate(0, 100px);
}

#portfolio .project-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 0, 92, 0.15);
  z-index: 55;
  transform: translateX(-100%);
}

#portfolio .project .project-heading {
  position: absolute;
  top: 40%;
  left: 5%;
  opacity: 0;
  transform: translate(0, 50px);
  z-index: 66;
}

#portfolio .project-heading .line {
  height: 3px;
  background-color: #fff;
}

#portfolio .project-heading h4 {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 0.5em 0;
}

#portfolio .project-heading p {
  color: #fff;
}

#portfolio .project a {
  overflow: hidden;
}

#portfolio .project a,
#portfolio .project img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#portfolio .project img {
  object-fit: cover;
  opacity: 1;
  transition: transform 0.8s cubic-bezier(0.2, 0.96, 0.34, 1);
}

#portfolio .project a:hover img {
  transform: scale(1.05) translateZ(0);
  transition: transform 1500ms cubic-bezier(0.2, 0.96, 0.34, 1);
}

#portfolio .project--1 {
  width: 37vw;
  margin-right: 3.5vw;
}

#portfolio .project--2 {
  width: 46vw;
  vertical-align: top;
}

#portfolio .project--3 {
  width: 42vw;
  margin-right: 2.5vw;
}

#portfolio .project--4 {
  width: 42vw;
}

#portfolio .project--5 {
  display: block;
  width: 66vw;
  margin: 4rem auto 0;
}

#portfolio .project--5:before {
  padding-top: 47%;
}

#portfolio .project:before {
  content: "";
  display: inline-block;
  position: relative;
  width: 100%;
}

#portfolio .project--1:before {
  padding-top: 120%;
}

#portfolio .project--2:before {
  padding-top: 60%;
}

#portfolio .project--3:before {
  padding-top: 80%;
}

#portfolio .project--4:before {
  padding-top: 120%;
}

@media (max-width: 768px) {
  #portfolio {
    height: auto;
  }

  #portfolio .overview {
    padding: 75px 0;
  }
  #portfolio .project-overview {
    padding: 75px 1rem;
  }

  #portfolio .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
  }

  #portfolio .project {
    width: 80vw;
    min-height: 50vh;
    margin-bottom: 3rem;
    margin-right: 0;
  }

  #portfolio .project--2 img {
    object-position: -41px;
  }

  #portfolio .project--5 {
    margin: 0 0 3rem;
  }

  #portfolio .project .project-heading {
    opacity: 1;
  }

  #portfolio .project-overlay {
    transform: translateX(0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  #portfolio .project-overview .project-heading span {
    font-size: 0.6rem;
  }

  #contact h2 {
    font-size: 1.5rem;
  }
}

.call-to-action h2 {
  margin: 0 auto 20px;
}

.btn {
  font-size: 0.75rem;
}

.btn {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  border: none;
  border-radius: 300px;
  font-weight: 700;
  text-transform: uppercase;
}
.btn-xl {
  padding: 15px 30px !important;
  margin: 30px;
}

.footer .btn-xl {
  margin: 30px 40px;
}

.footer-info img {
  width: auto;
  height: auto;
  max-height: 30px;
}

.stn-btn {
  display: inline-block;
  position: relative;
  color: rgb(81, 40, 136);
  background: #fff;
  border: 1px solid #fff;
  border-radius: 300px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  padding: 10px 15px;
  font-size: 0.7rem;
  overflow: hidden;
  z-index: 90;
}

.stn-btn:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(81, 40, 136);
  border-radius: 300px;
  -webkit-transform: translateX(-110%);
  -ms-transform: translateX(-110%);
  transform: translateX(-110%);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.stn-btn:hover:before {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.stn-btn:hover {
  color: #fff;
  text-decoration: none;
  outline: none;
}

::-moz-selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
::selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
img::-moz-selection {
  color: white;
  background: transparent;
}
img::selection {
  color: white;
  background: transparent;
}
img::-moz-selection {
  color: white;
  background: transparent;
}
.logo {
  padding: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.logo:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-5px);
  }
}
@keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

#navlogo {
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.hidden-xs {
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  display: none;
}
/* MESSAGE FORM */

.form-control {
  margin-bottom: 15px;
  padding: 10px;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.form-control:focus {
  border-color: #8e44ad;
}
.form-group > input {
  margin-right: 15px;
  min-height: 45px;
}
textarea {
  resize: none;
}
form > button {
  margin-right: 5px;
}

.alert {
  font-weight: bold;
  display: none;
}

@media (max-width: 768px) {
  .form-control.sbj {
    min-height: 45px;
  }
}

@media only screen and (min-width: 694px) {
  .form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -ms-flex;
    display: -moz-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }
  .sbj {
    -webkit-box-flex: 1;
    flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    -moz-flex: 1;
    min-width: 200px;
    min-height: 45px;
  }
}

.header-content span {
  display: block;
  margin-bottom: 15px;
}

.footer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  background-color: #42007e;
  padding: 50px;
}

@media (max-width: 768px) {
  .footer {
    padding: 10px 0;
  }

  .footer .footer-social {
    padding-top: 30px;
  }
}

.footer .footer-social,
.footer .footer-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #fff;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 0.8rem;
}

.footer-social .soc-box {
  opacity: 0.7;
  transition: opacity 600ms cubic-bezier(0.23, 1, 0.32, 1);
}

.footer-info {
  padding: 10px;
}

@media (max-width: 768px) {
  .footer .footer-info {
    display: none;
  }
}

.footer .footer-text {
  color: #fff;
  padding: 0 10px;
  letter-spacing: 1px;
  line-height: 37px;
  font-size: 1rem;
}

.progressbar-text {
  font-size: 2rem !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 300 !important;
}

/* ANIMATIONS */

.expand-bar {
  -ms-flex-preferred-size: 35%;
  flex-basis: 35% !important;
  background-color: rgba(171, 86, 193, 0.3) !important;
}

.scene {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  pointer-events: auto !important;
}

@media (max-width: 768px) {
  .scene {
    height: 100%;
  }

  #header .scene {
    height: 100vh;
  }
}

.canvas-layer {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
}

/* performance */
.will-an {
  will-change: transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

#defaultCanvas0 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -5;
}

.particle-bg-1,
.particle-bg-2 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -5;
  opacity: 0.5;
}

.section-name-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
  white-space: nowrap;
}

.section-name {
  opacity: 0;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 100;
  letter-spacing: 2.5px;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .section-name-wrapper {
    position: relative;
  }

  .section-name-wrapper .section-name {
    margin: 0;
    text-align: center;
    opacity: 1;
    font-size: 2.6rem;
  }
}

.p-contact a {
  color: #fff;
}

.p-contact a:hover {
  text-decoration: none;
}

#messageMe {
  overflow: hidden;
  padding: 10px;
}

@media (max-width: 768px) {
  .stn-btn.btn-xl {
    display: block;
  }
}

#progressline {
  width: 250px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.taskly {
  background-color: rgba(31, 90, 182, 0.4);
}

.project-overview {
  position: relative;
  width: 100%;
  padding: 3rem 4rem;
}

.project-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  opacity: 0;
  transform: scale(0);
}

@media (max-width: 1024px) {
  .project-overview {
    padding: 0.5rem 1rem;
  }

  .project-logo {
    width: 80px;
    height: 80px;
  }
}

#portfolio .project-overview .project-heading {
  position: relative;
  margin-left: 5%;
  color: #fff;
}

#portfolio .project-overview .project-heading > h2 {
  margin: 0;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
}

#portfolio .project-main {
  background: rgba(20, 20, 20, 0.2);
  padding: 1rem 2rem;
}

#portfolio .project-overview .project-description {
  width: 40vw;
  color: #fff;
  transform: translateY(50px);
  opacity: 0;
}

#portfolio .project-description h4 {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
}

#portfolio .project-overview .project-description--right {
  margin-left: 40vw;
  margin-top: 25vh;
}

#portfolio .project-description ul > li {
  margin-bottom: 5px;
  font-size: 0.9rem;
}

#portfolio .project-overview .project-photo {
  position: absolute;
  width: 32vw;
  right: 0;
  top: -5vw;
  transform: translateY(50px);
  opacity: 0;
  z-index: 55;
}

#portfolio .project-overview .project-photo--left {
  left: 4vw;
  right: auto;
  top: 0;
}

@media (max-width: 1024px) {
  #portfolio .project-overview .project-description {
    width: 100%;
  }

  #portfolio .project-overview .project-photo {
    position: relative;
    width: 100%;
  }
}

.gplay {
  width: 200px;
}

.project-overview .stn-btn {
  margin-bottom: 15px;
}

.oublier {
  background-color: rgba(159, 68, 242, 0.4);
}

#portfolio .oublier .box {
  position: relative;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translate(0, 50px);
  z-index: 56;
}

#portfolio .reimburseme .pane {
  width: 0;
  opacity: 0;
  margin-left: -4rem;
  align-items: flex-end;
  padding: 0 2rem;
}

#portfolio .reimburseme .pane .project-logo {
  height: auto;
  width: 200px;
}

#portfolio .reimburseme .box {
  top: 100%;
  left: 50%;
  margin-left: 16%;
}

.oublier .box > .project-logo {
  display: block;
  width: 200px;
  height: auto;
  opacity: 1;
  transform: scale(1);
}

#portfolio .absolute-row {
  position: absolute;
  top: 0;
  left: 200px;
  padding: 0 10px 0 140px;
  max-height: 80px;
  background: #512888;
  overflow: hidden;
  width: 0;
  display: none;
}

.oublier .absolute-row h4 {
  color: #fff;
  opacity: 0;
  text-align: center;
}

#portfolio .oublier .project-photo {
  top: -20vh;
  right: -2vw;
  width: 66vw;
}

#portfolio .oublier .project-photo.project-photo--relative {
  position: relative;
  width: 20vw;
  top: 10vw;
  right: auto;
  margin-right: 4.5vw;
}

#portfolio .oublier .project-photo--relative ~ .project-description {
  margin-top: 10rem;
}

.oublier .project-description a {
  border-bottom: 1px solid #f24f00;
}

.oublier a:hover {
  color: #fff;
  border-bottom-color: #fff;
}

@media (max-width: 1024px) {
  #portfolio .oublier .box {
    width: 100%;
    height: 60px;
  }

  .oublier .box > .project-logo {
    width: 30%;
  }

  #portfolio .oublier .project-photo {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }

  #portfolio .oublier .project-photo.project-photo--relative {
    width: 90%;
  }
}

.babys-dream {
  background: rgba(241, 156, 187, 0.4);
}

.babys-dream .project-main {
  transform: translate(0, 50px);
  opacity: 0;
}

#portfolio .babys-dream .project-photo {
  position: relative;
  width: 65%;
  top: 0;
}

#portfolio .babys-dream .project-photo:first-child {
  margin-right: 15px;
}

#portfolio .babys-dream .project-photo:nth-child(2) {
  margin-left: 15px;
}

#portfolio .photo-row {
  padding: 2rem 0;
}

.babys-dream .project-description a {
  border-bottom: 1px solid #f19cbb;
}

.babys-dream .project-description a:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
}

#stundji-path {
  fill: transparent;
  transition: fill 600ms ease-out;
}

@media (max-width: 768px) {
  #portfolio .babys-dream .project-photo {
    width: 100%;
  }

  #portfolio .babys-dream .project-photo:first-child,
  #portfolio .babys-dream .project-photo:nth-child(2) {
    margin-left: 0;
    margin-right: 0;
  }
}
